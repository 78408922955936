<template>
    <div id="chips-last-statement">
        <v-chip :color="getColor()" outlined small>
            <span :class="`${getColor()}--text`">
                <template v-if="date"> {{ previous_text }} {{ moment(date).fromNow() }} {{ after_text }} </template>
                <template v-else>
                    {{ fallback_text }}
                </template>
            </span>
        </v-chip>
    </div>
</template>

<script>
export default {
    name: 'ChipsLastStatement',
    props: {
        date: {
            type: String,
            require: true,
        },
        danger: {
            type: Number,
            require: false,
            default: 345600,
        },
        warning: {
            type: Number,
            require: false,
            default: 172800,
        },
        previous_text: {
            type: String,
        },
        after_text: {
            type: String,
        },
        colorless: {
            type: Boolean,
        },
        fallback_text: {
            type: String,
            default: function () {
                return this.$t('error_display.no_data')
            },
        },
    },
    methods: {
        getColor() {
            let color = 'success'
            if (this.colorless) {
                color = 'disable'
            } else if (!this.date) {
                color = 'error'
            } else {
                let dif = Math.abs((new Date(this.date).getTime() - new Date().getTime()) / 1000)
                if (dif > this.danger) {
                    color = 'error'
                } else if (dif > this.warning) {
                    color = 'warning'
                }
            }
            return color
        },
    },
}
</script>
